import logo from './logo.svg';
import './App.css';
import './index.css';

import { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from "react-apexcharts";

import RedArrowLogo from './red arrow logo.png'
import GreenArrowLogo from './green arrow logo.png'
import RedArrowWhiteLogo from './red arrow logo white text.png'
import GreenArrowWhiteLogo from './green arrow logo white text outline.png'
import MainLogo from './main logo.png'

function App() {

const[a, setA]  = useState([])
const[data, setData]  = useState([])
const[chart, setChart]  = useState([])
const[min, setMin] = useState(0)
const[max, setMax] = useState(0)
const [open, setOpen] = useState(0)
const [close, setClose] = useState(0)
const [image, setImage] = useState(MainLogo)
const [ticker, setTicker] = useState("GM")
const [tempTicker, setTempTicker] = useState(ticker)
const[skip, setSkip] = useState(false)
const[callDraw, setCallDraw] = useState(false)
const[date, setDate] = useState("")
const[change, setChange] = useState(0)
const [odds,setOdds] = useState({stockTicker: "loading"})
const [oddsData,setOddsData] = useState([])
const [priceOdds,setPriceOdds] = useState([])
const [priceOddsData,setPriceOddsData] = useState([])
const[chooseDate, setChooseDate] = useState("")


useEffect(()=>{    
  dripData()
},[a, callDraw])

useEffect(()=>{    
  if(chooseDate.length+1 <10 || tempTicker.length == 0){
    return undefined
  } 
  loadOddsAndPriceOdds()

},[chooseDate])


useEffect(()=>{

    setChart(   
      <Chart
      options={options}
      series={series}
      type="candlestick"
      // width="1000px"
      className={"w-full"}
      />
      )    

},[data])

function timeout(ms){
 return new Promise( resolve=> setTimeout(resolve ,ms))   
}

function loadOddsAndPriceOdds(){  
  

let tick = (tempTicker != undefined) ? tempTicker.toUpperCase() : tempTicker
let oddsEndpoint = `https://odds-provider.herokuapp.com/odds/o139p3lct2axfvt/${chooseDate}/`
let priceoddsEndpoint = `https://odds-provider.herokuapp.com/price/o139p3lct2axfvt/${chooseDate}/${tick}`
let auth = "Basic U1FMRGFkZHk6OWVhODkyM2EtNzgwZi00ODE5LWJiYTEtZjNiZTRjYjYzY2FmLTNkY2E2NGJlLWYxNTEtNDUyZC1iODM0LWQwNTZjYzA4MDc0Mw=="

axios({
  // "url": oddsEndpoint,
  "url": priceoddsEndpoint,
  "method": "POST",
  "headers": {
      "Authorization": auth
  }
}).then((res)=>{
  // setOddsData(res.data)
  let d = res.data[0]
  
  setPriceOddsData([
    d.minusPlusPercentOdds,
    d.minusNinePercentOdds,
    d.minusEightPercentOdds,
    d.minusSevenPercentOdds,
    d.minusSixPercentOdds,
    d.minusFivePercentOdds,
    d.minusFourPercentOdds,
    d.minusThreePercentOdds,
    d.minusTwoPercentOdds,
    d.minusOnePercentOdds,
    d.zeroPercentOdds,
    d.onePercentOdds,
    d.twoPercentOdds,
    d.threePercentOdds,
    d.fourPercentOdds,
    d.fivePercentOdds,
    d.sixPercentOdds,
    d.sevenPercentOdds,
    d.eightPercentOdds,
    d.ninePercentOdds,
    d.plusPercentOdds,
  ]);
}).catch(e=>{
  console.log(e)
})

axios({
  // "url": oddsEndpoint,
  "url": oddsEndpoint,
  "method": "POST",
  "headers": {
      "Authorization": auth
  }
}).then((res)=>{
  setOddsData(res.data)
  console.log(res.data)
}).catch(e=>{
  console.log(e)
})

  


}


function loadData(ticker){
  let url =`https://playback-query.herokuapp.com/playback/${ticker}/${chooseDate}`
  axios.get(url).then((r)=>{
    console.log(r)
    // arr =[time, open, high, low, close]
    let arr = 
      parseOHLC(r.data)      

    setA(arr)
    setMin(arr[0][3])
    setMax(arr[0][2])
    setOpen(arr[0][1])    
    // setClose(arr[arr.length-1][4])
    setDate(new Date(r.data[0].t).toLocaleDateString())
    setData([])

  }).catch((e)=>{
    console.log(e)
    setSkip(false)
    setTicker("INVALID TICKER")
  })
}

const dripData = async ()=>{
  // setData(data)
  let length = a.length
  if(length == 0) return false 
  let seconds = 15
  let wait = (1000 * seconds) / (length)  
  // reset data on first call

  while(length>0)
  {
    // console.log(ticker, oldTicker)
    let x = a.shift()
    data.push(x)
    // setData([...data,x])
    setData([...data])
    if(x[4]>open){
      setImage(GreenArrowWhiteLogo)
    } else if(x[4]<open){
      setImage(RedArrowWhiteLogo)
    }
      await timeout(wait)
      setChange(x[4]-open)
      setClose(x[4])
      length--;      
    }
    await setSkip(false)
    // await setData([])
}

function parseOHLC(ohlc){
  return ohlc.map((a)=>{
    return [new Date(a.t).getTime(), a.o, a.h, a.l, a.c]
  })
}


let options= {
    chart: {
     type: 'candlestick',
     animations: {
      enabled: false,      
      },
      foreColor: '#fff'
    },
    yaxis: {
      show: true,
      showForNullSeries: true,      
      opposite: false,      
      tickAmount: 3,
      // min: a.reduce((min,num)=> num.l < min ? num.l : min, min),
      // max: a.reduce((max,num)=> num.h > max ? num.h : max, max),      
      floating: false,
      decimalsInFloat: 2,    
  },
    xaxis: {
      type: 'datetime'
    },
  }
let series = [{
    data: data
    }]


  function getOdds(symbol){
    for(let o of oddsData){      
      if(o.stockTicker == symbol.toUpperCase()){
        setOdds(o)
        break;
      }
    }
  }


function displayChange(){
{/* PRICE DIV */}
return(
  <div className='my-auto lg:text-6xl text-md font-bold'>
  
  {(change>0) ? 
  <div className='text-center text-green-600'>
  <div className=''>${Math.round(change*100)/100}</div>
  <div className='text-sm'>{Math.round((change/((open!=0)?open:1))*100*10)/10}%</div>
  </div>
  :
  <div className='text-center text-red-600'>
  <div className=''>${Math.round(change*100)/100}</div>
  <div className='text-sm'>{Math.round((change/((open!=0)?open:1))*100*10)/10}%</div>
  </div>
}
</div>
    )
}


function getDayOfWeek(){
  let day = new Date(new Date(chooseDate).toISOString()).getDay()
  switch(day){
    case 0:
      return "Mon"
      break;
    case 1:
      return "Tue"
      break;
    case 2:
      return "Wed"
      break;
    case 3:
      return "Thu"
      break;
    case 4:
      return "Fri"
      break;
  }


}

function displayOdds(){


{/* PRICE DIV */}
return(
<div className='my-auto text-center'>
<p>Open: {`$${Math.round(open*100)/100}`}</p>
<p>Close: {`$${Math.round(close*100)/100}`}</p>    
<br></br>
<p>Green Odds: {`${(odds.stockTicker != "loading")? Math.round(odds["greenOdds"+getDayOfWeek()]*100) : 0}%`}</p>
<p>Red Odds: {`${(odds.stockTicker != "loading")? Math.round(odds["redOdds"+getDayOfWeek()]*100) : 0}%`}</p>
<br></br>    
<p>Odds at price : 
{Math.round(
  (priceOddsData[(change>0)?Math.round(100*change/open)+10 : Math.round(100*change/open)+10])*100)/100}%</p>  
{(change>0)?
<p>Odds above: 
{
Math.round(
(priceOddsData.slice(
  priceOddsData.indexOf(
    (priceOddsData[(change>0)?Math.round(100*change/open)+10 : Math.round(100*change/open)+10])
    )
  ).reduce((roll,val)=>{
  return roll + val
},0)    
-    
(priceOddsData[(change>0)?Math.round(100*change/open)+10 : Math.round(100*change/open)+10])
)) 
}%</p>  
:
<p>Odds Below:
{
Math.round(
(priceOddsData.slice(0,
  priceOddsData.indexOf(
    (priceOddsData[(change>0)?Math.round(100*change/open)+10 : Math.round(100*change/open)+10])
    )
  ).reduce((roll,val)=>{
  return roll + val
},0)    
-    
(priceOddsData[(change>0)?Math.round(100*change/open)+10 : Math.round(100*change/open)+10])
)) 
}%</p>    
}
</div>
    )
}



  return (
    // <div className='bg-slate-900'>
    <div className='lg:w-full w-1/2 mx-auto'>
      {/* <h1 className=' text-center font-bold text-4xl'>{ticker}</h1> */}
      <div className='grid grid-cols-3 px-20 pt-5 mb-40'>
          <input 
          maxLength={5}
          className="rounded-2xl text-2xl mx-auto text-slate-900 border-black border-2 p-2 text-center sm:w-4/5"
          placeholder='AAPL'
          type={"text"}
          onChange={(e)=>{
            setTempTicker(e.target.value)
          }}/>        
          <input 
          className="rounded-2xl text-2xl mx-auto text-slate-900 border-black border-2 p-2 text-center sm:w-4/5"
          maxLength={10}
          placeholder='2023-01-15'
          type="text"
          onChange={(e)=>{
            setChooseDate(e.target.value)
            // getOddsAndPriceOdds()
          }}
          value={chooseDate}
          />                  
          {
            (skip == false)?
            <button
          className='border-4 border-slate-900 bg-slate-900 text-white rounded-2xl sm:w-4/5'
          onClick={(e)=> {
            setTicker(tempTicker)
            loadData(tempTicker)       
            setCallDraw(!callDraw)
            getOdds(tempTicker)
            setSkip(true) 
          }}> BUTTON</button>
          :        <button
          className='border-4 border-slate-900 bg-slate-900 text-white opacity-50 rounded-2xl sm:w-4/5'
          onClick={(e)=> {               
          }}> BUTTON</button>
        }
      </div>
    <div className=" grid grid-cols-1 -mt-16 p-20">
      <div id="main-container" className='mx-auto p-10  grid lg:grid-cols-3 '>  
        <img src={image} className={" border-black rounded-3xl w-5/5"}/>  
        {displayChange()}
        {displayOdds()}
      </div>  
  <div id ="chart-container" className='p-4 border-black border-4 rounded-3xl mb-40'>
    <h1 className='text-left font-bold pl-4 text-4xl'>{ticker.toUpperCase() + " " + date}</h1>    
    {chart}
  </div>
    </div>
    </div>
  );
}

export default App;
